<script setup>
const { locale, locales, setLocale } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  const list = [];
  locales.value.forEach(item => {
    list.push({
      value: item.code,
      label: item.name,
    });
  });
  return list;
});

const currentLocale = computed({
  get() {
    return locale.value;
  },
  async set(localeCode) {
    setLocale(localeCode)
    await navigateTo(switchLocalePath(localeCode));
  }
});
</script>

<template>
  <MosaicUiDropdownsDefaultDropdown v-model="currentLocale" :options="availableLocales" />
</template>
